import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { selectAllRoutes, selectTomorrowAllRoutes, selectAllActiveDrivers, selectAllActiveVehicles, selectHistoryRoutes } from "./../../store";
import PersonnelSection from "./PersonnelSection";
import { Modal, Button } from "react-bootstrap";
import { AuthService } from "../../services";
import moment from 'moment';

const RouteView = () => {
  const params = useParams();
  const allRoutes = useSelector(selectAllRoutes);
  const tomorrowRoutes = useSelector(selectTomorrowAllRoutes);
  const historyRoutes = useSelector(selectHistoryRoutes);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const currentRoute = (allRoutes.find(item => item.id === params.id)) || (tomorrowRoutes.find(item => item.id === params.id)) || (historyRoutes.find(item => item.id === params.id));
  const currentVehicle = vehicles.find(item => item.id === currentRoute?.vehicle );
  const currentDriver = drivers.find(item => item.id === currentRoute?.driver);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const paramsQuery = new URLSearchParams(window.location.search);
  const scheduleDate = paramsQuery.get('dateSchedule');
  const navigate = useNavigate();
  const closeModal = () => {
    setShowVehicleDetails(false);
  }
  const openModal = () => {
    setShowVehicleDetails(true);
  }
  const getRelatedOutboundRoutesForThisView = () => {
    if (allRoutes.find(item => item.id === params.id)) {
      return allRoutes.filter(item => item.type==='outbound');
    }
    if (tomorrowRoutes.find(item => item.id === params.id)) {
      return tomorrowRoutes.filter(item => item.type==='outbound');
    }
    if (historyRoutes.find(item => item.id === params.id)) {
      return historyRoutes.filter(item => item.type==='outbound');
    }
  }
  const directToDashboad = () => {
    if (tomorrowRoutes.find(item => item.id === params.id)) {
      navigate(`/trans-routes/schedule?dateSchedule=${moment(tomorrowRoutes.find(item => item.id === params.id).schedule_date).format('YYYY-MM-DD')}`);
    } else {
      if (historyRoutes.find(item => item.id === params.id)) {
        navigate(`/trans-routes/history`);
      } else {
        navigate(`/trans-routes/dashboard`);
      }
    }
  }
  const edit = () => {
    if (scheduleDate) {
      navigate(`/trans-routes/edit/${currentRoute.id}?dateSchedule=${scheduleDate}`)
    } else {
      navigate(`/trans-routes/edit/${currentRoute.id}`)
    }
  }
  return (
    <>
      <div className="list row">
        <div className="col-md-12 text-primary mb-2">
          <h5>{currentRoute?.name} <button className="btn btn-link btn-sm" onClick={() => directToDashboad()}>Back</button> {AuthService.canAddOrEditRoutes() && <button className="btn btn-primary btn-sm" onClick={() => edit()}>Update</button>}</h5>
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-4 col-sm-6 col-xs-6">
          <div className="mb-2">Vehicle Number: {currentVehicle?.vehicle_number} <button type="button" className="btn btn-info btn-sm" onClick={() => openModal()}>Vehicle Details</button></div>
          <div className="mb-2">Driver Name: {currentDriver?.name}</div>
          <div className="mb-2">Route Type: { currentRoute?.type }</div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">
          <div className="mb-2">Route Start Time: {currentRoute?.start_time && (new Date(currentRoute.start_time))?.toLocaleTimeString()}</div>
          <div className="mb-2">Route End Time: {currentRoute?.end_time && (new Date(currentRoute.end_time))?.toLocaleTimeString()}</div>
          {currentRoute?.type === 'inbound' && <div className="mb-2">Arrive Center Time: {currentRoute?.end_time && (new Date(currentRoute.end_time))?.toLocaleTimeString()}</div>}
          {currentRoute?.type === 'outbound' &&<div className="mb-2">Leave Center Time: {currentRoute?.start_time && (new Date(currentRoute.start_time))?.toLocaleTimeString()}</div>}
          {currentRoute?.type === 'outbound' && <div className="mb-2">Estimated Start Time: {currentRoute?.estimated_start_time && (new Date(currentRoute.estimated_start_time))?.toLocaleTimeString()}</div>}
        </div>
        <div className="col-md-4 col-sm-6 col-xs-6">
          <div className="mb-2">Start Mileage: { currentRoute?.start_mileage}</div>
          <div className="mb-2">End Mileage: { currentRoute?.end_mileage}</div>

        </div>
      </div>
      <div className="list row">
        <div className="col-md-12 mb-4">
          {currentRoute && <PersonnelSection transRoutes={[currentRoute]} showCompletedInfo={true} showGroupInfo={true} isInbound={currentRoute?.type === 'inbound' } allowForceEdit={historyRoutes.find(item => item.id === params.id)? false : true} sectionName="Personnel Status (click on each user to edit)" relatedOutbound={getRelatedOutboundRoutesForThisView()} vehicle={currentVehicle} driverName={currentDriver?.name}/>}
        </div>
      </div>
      <Modal show={showVehicleDetails} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mb-2">Vehicle Number: {currentVehicle?.vehicle_number}</div>
            <div className="mb-2">Tag: {currentVehicle?.tag}</div>
            <div className="mb-2">EzPass: {currentVehicle?.ezpass}</div>
            <div className="mb-2">GPS: {currentVehicle?.gps_tag}</div>
            <div className="mb-2">Capacity: {currentVehicle?.capacity}</div>
            <div className="mb-2">Status: {currentVehicle?.status}</div>
            <div className="mb-2">Mileage: {currentVehicle?.mileage}</div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
    
  );
};

export default RouteView;