import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomerService, TransRoutesService } from "../../services";
import { selectAllActiveDrivers, selectAllActiveVehicles } from "./../../store";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { route } from "express/lib/router";

const RouteSignatureList = () => {
  const params = new URLSearchParams(window.location.search);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  
  const navigate = useNavigate();
  const [dateSelected, setDateSelected] = useState(new Date());
  const [routes, setRoutes] = useState([]);

  const redirectToDashboard = () => {
    navigate(`/trans-routes/dashboard`);
  }

  useEffect(() => {
    TransRoutesService.getAll(moment(dateSelected)?.format('MM/DD/YYYY')).then(data => {
      const routesResults = data.data;
      const finalRoutes = routesResults.map(async (routeItem) => {
        const dateArr = moment(dateSelected)?.format('MM/DD/YYYY')?.split('/') || [];
        try {
          const result = await CustomerService.getAvatar(`${routeItem.id}_${routeItem.driver}_${dateArr[0]}_${dateArr[1]}`);
          return result?.data ? Object.assign({}, routeItem, {signature: result?.data}) : routeItem;
        } catch (ex) {
          return routeItem;
        }
      });
      Promise.all(finalRoutes).then(finalRoutesData => {
        console.log('finalRoutes', finalRoutesData);
        setRoutes(finalRoutesData);
      })
    })
  }, [dateSelected])


  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>View Routes Signatures <button className="btn btn-link btn-sm" onClick={() => {redirectToDashboard()}}>Back To Dashboard</button></h5> 
        </div>
      </div>
      
      <div className="list row">
				<div className="col-md-12">
					<div className="mb-4 col-md-4 col-sm-12">
						Select Date to start: <DatePicker selected={dateSelected} onChange={(v) => setDateSelected(v)} />
					</div>
					<table className="personnel-info-table">
            <thead>
              <tr>
                <th>Route Name</th>
                <th>Driver</th>
                <th>Route End Time</th>
                <th>Route Type</th>
								<th>Signature</th>
              </tr>
            </thead>
            <tbody>
							{
								routes && routes?.map(({id, name, end_time, driver, type, signature}, index) => {
									return (<tr key={index}>
										<td>{name}</td>
										<td>{drivers.find((d) => d.id === driver)?.name}</td>
										<td>{end_time?moment(end_time).format('HH:ss'): ''}</td>
                    <td>{type}</td>
										<td>
                      {/* {images.find((img) => img.id === id)?.image && <img width="100px" src={`data:image/jpg;base64, ${images.find((img) => img.id === id)?.image}`}/>} */}
                      {signature && <img width="100px" src={`data:image/jpg;base64, ${signature}`}/>}
                    </td>
									</tr>)
								})
							}
						</tbody>
					</table>
				</div>
			</div>
    </>
    
  );
};

export default RouteSignatureList;